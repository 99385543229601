import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'Home',
    },
    meta: {
      title: '',
    },
  },
  {
    name: 'Home',
    path: '/',
    component: () => import('./view/home'),
    meta: {
      title: 'Trang chủ',
    },
  },
  {
    name: 'About',
    path: '/about',
    component: () => import('./view/about'),
    meta: {
      title: 'Về chúng tôi',
    },
  },
  {
    name: 'Privacy',
    path: '/privacy',
    component: () => import('./view/privacy'),
    meta: {
      title: 'Chính sách bảo mật',
    },
  },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title + ' - Trò chơi Việt Nam tốt nhất';
  }
  next();
});

export { router };
